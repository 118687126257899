import React from "react"
import { Link } from "gatsby"
import Seo from "../components/global/Seo"
import Header from "../components/global/header"
import Footer from "../components/global/footer"

export default function DyakuyuPage() {
  return (
    <>
      <Seo
        title={`Поздоровляємо, заявку відпралено`}
        description={``}
        image={``}
        url={``}
      />
      <Header />
      <div className="container-my">
        <h1>Ура! Заявка відправлена!</h1>
        <p>
          Ваше звернення успішно відправлено, дякуємо.
          <br />
          Зазвичай ми відповідаємо протягом 15 хвилин. Ви можете Перейти на{" "}
          <Link to="/" replace>
            головну сторінку
          </Link>{" "}
          і продовжити роботу із сайтом.
        </p>
      </div>
      <Footer />
    </>
  )
}
